import React, {useContext, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import logo from "../../images/logo.png";
import {RoutesList} from "../../../routes";

const MobNav = ({handleToggleMobNav}) => {
    const {storeblog} = useContext(Context)
    const categoriesNames = ['Finans', 'Sağlık', 'Diğer'];
    const categories = ['FINANCE', 'HEALTH', 'ANOTHER'];
    const navigate = useNavigate();

    useEffect(()=>{
        console.log(storeblog.selectedCategory , 'storeblog.selectedCategory')
    },[storeblog.selectedCategory])

    const handleAboutUs = () => {
        navigate(RoutesList.ABOUT_US_ROUTER);
        handleToggleMobNav()
    }

    return (
        <div className='main-mob-menu'>

                            <ul className="main-mob-menu_list">
                                {categories.map((e,i) =>
                                    <li className="nav-item" key={i}>
                                        <Link to={`category/${categories[i]}`}
                                              onClick={() => {
                                                  storeblog.setSelectedCategory(e)
                                                  handleToggleMobNav()
                                                  console.log(e)
                                              }}
                                              className='nav-link cursor-pointer text-decoration-none'>
                                            {categoriesNames[i]}
                                        </Link>
                                    </li>
                                )}
                                {/*<hr className='bg-white w-100' style={{height: '2px'}}/>*/}
                                {/*<li className="nav-item" key='4-about'>*/}
                                {/*    <button*/}
                                {/*        key='aboutus'*/}
                                {/*        onClick={() => handleAboutUs()}*/}
                                {/*        className={'nav-link cursor-pointer text-decoration-none'}>*/}
                                {/*        О НАС*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                            </ul>

        </div>
    );
};

export default observer(MobNav);