import React, { useContext } from 'react';
import { Context } from '../../../index';
// import PopupBannerItem from '../Banners/PopupBannerItem';
import {useLocation, useParams} from "react-router-dom";
import BannerItem from '../Banners/BannerItem';


const ModalBanners = ({ onClick }) => {
    const { storeblog } = useContext(Context);

    return (
        <div className='w-full fixed-top h-100 bg-dark bg-opacity-50 flex-column px-2 d-flex align-items-center overflow-y-auto justify-content-start'>
            <div className='bg-white popup_banner_container py-4 my-5'>
                <h2 className='text-center popup_title mb-4'>Ana haberler</h2>
                <div className='d-flex flex-wrap align-items-stretch modal_banners_block px-4'>
                    {storeblog.popupBanners.map((banner, i) =>
                        <BannerItem
                            key={i}
                            post={banner.post}
                            photo={banner.mainImg}
                            link={banner.link}
                            title={banner.title}
                            bannerID={banner._id}
                            bannerSeoName={banner.seoName}
                            articleSeoName={'modal'}
                            type={'modal'}
                        />
                    )
                    }
                </div>
                <div className='popup_footer px-4 pt-3 mt-3 d-flex align-items-center justify-content-end border-top'>
                    <button className='popup_close_btn rounded-3 p-3 border-0 text-uppercase fw-bold text-white' onClick={onClick}>
                        Devam etmek
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalBanners;