import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import './ArticleItem.css'
import BannerList from "../Banners/BannerList";
import PostList from "./PostList";
import { Context } from "../../../index";
import Banner from "../Banners/BannerItem";

const ArticleItem = ({ post, title, id, articleSeoName }) => {
    const { storeblog } = useContext(Context);
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(post, 'text/html');
    const imgTags = parsedHtml.getElementsByTagName('img');

    let photo = '';
    for (let i = 0; i < imgTags.length; i++) {
        if (!imgTags[i].closest('.article_banner_box')) {
            photo = imgTags[i].getAttribute('src');
            break;
        }
    }

    const [isMore, setIsMore] = useState(false);
    const [articleContent, setArticleContent] = useState([]);

    useEffect(() => {
        storeblog.setSelectedCategory('');

        // Парсинг банерів із JSON у статті
        const parseArticle = (html) => {
            // Регулярний вираз для пошуку JSON-банерів у тексті
            const bannerRegex = /{"banner":.*?}/g;
            const parts = html.split(bannerRegex); // Розбиваємо текст на частини між банерами
            const banners = [...html.matchAll(bannerRegex)]; // Збираємо всі банери у форматі JSON
            // Створюємо масив контенту
            const content = [];
            parts.forEach((part, index) => {
                content.push(part); // Додаємо частину тексту статті
                if (banners[index]) {
                    // Парсимо JSON дані банера
                    const bannerData = JSON.parse(banners[index][0]);
                    // console.log(bannerData)
                    // Додаємо компонент банера до масиву
                    content.push(
                        <Banner
                            key={bannerData.banner}
                            bannerID={bannerData.banner}
                            title={bannerData.title}
                            link={bannerData.link}
                            photo={bannerData.photo}
                            type={'article'}
                            articleId={id}
                        />
                    );
                }
            });

            return content;
        };

        // Парсимо пост і зберігаємо оброблений контент у стан
        setArticleContent(parseArticle(post));

    }, [post, storeblog]);

    return (
        <div className='px-9'>
            <div className='article_box'>
                {post !== ''
                    ? (
                        isMore
                            ? (
                                // Рендеримо статтю з обробленими банерами
                                <div className='article_body'>
                                    {articleContent.map((item, index) => {
                                            if(typeof item === 'string'){
                                                return <div key={index} dangerouslySetInnerHTML={{ __html: item }}></div>
                                            }else{
                                                return (<React.Fragment key={index}>{item}</React.Fragment>)
                                            }
                                        }
                                    )}
                                </div>
                            )
                            : (
                                <div className='article_body'>
                                    <h2>
                                        <img src={photo} alt="photo" />
                                    </h2>
                                    <h2>{title}</h2>
                                    <button className='article_more_btn' onClick={() => setIsMore(!isMore)}>Daha fazlasını göster</button>
                                </div>
                            )
                    )
                    : (
                        <div className='article_body'>
                            <div className='loader_box'>
                                <div className="loader"></div>
                            </div>
                        </div>
                    )
                }
                <div className='banners_box'>
                    <BannerList id={id} articleSeoName={articleSeoName}/>
                </div>
            </div>
            <div>
                <hr className='my-6 w-[90%] mx-auto' />
                <div className='d-flex flex-wrap justify-content-center mx-2'>
                    <PostList />
                </div>
            </div>
        </div>
    );
};

export default observer(ArticleItem);
