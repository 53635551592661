import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import BannerItem from "./BannerItem";
import {observer} from "mobx-react-lite";

const BannerList = ({id, articleSeoName}) => {
    const {storeblog} = useContext(Context)
    const [page, setPage] = useState(1);
    const limit = 100;

    useEffect(() => {
       storeblog.GetBanners(page, limit, id);
    }, [id]);


    return (
        <div>
            {storeblog.randomBanners.map((banner, i) =>

                    <BannerItem
                        key={i}
                        photo={banner.mainImg}
                        link={banner.link}
                        title={banner.title}
                        bannerID={banner._id}
                        articleSeoName={articleSeoName}
                        bannerSeoName={banner.seoName}
                        articleId={id}
                    />
                )
            }
        </div>
    );
};

export default observer(BannerList);